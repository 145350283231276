<template>
  <v-card elevation="0" color="#EEF2F7" class="rounded-4 d-flex dashboard-minimize">
    <v-col style="padding: 20px 30px">
      <v-row class="ma-0 mb-5">
        <span class="fz-16 fw-600 lh-22 text-black">Income</span>
      </v-row>
      <v-row class="ma-0 mb-5 justify-space-between">
        <span class="fz-16 lh-22 primary--text">{{ periods[0].period }}-{{ periods[periods.length - 1].period }}</span>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
              class="fz-16 lh-22 font-weight-medium text-black"
            >
              {{ totalValue.paidRub | numberFormat }}₽ / {{ totalValue.rub | numberFormat }}₽
            </span>
          </template>
          <template>
            ${{ totalValue.paidUsd | numberFormat }} / ${{ totalValue.usd | numberFormat }}
          </template>
        </v-tooltip>
      </v-row>
      <v-row class="ma-0 relative">
        <div
          :style="{'left': tooltip.x + 'px'}"
          style="top: -60px"
          v-if="tooltip"
          class="tooltip fz-13 fw-600 white--text one-line d-flex align-center"
        >
          <div class="align-self-start">
            {{ tooltip.name }}:&nbsp;
          </div>
          <div class="d-flex flex-column">
            <div>
              {{ +Math.round(tooltip.paidRub) | numberFormat }}₽ / {{ +Math.round(tooltip.totalCostRub) | numberFormat }}₽
            </div>
            <div>
              ${{ (+tooltip.paidUsd).toFixed(2) | numberFormat }} / ${{ (+tooltip.totalCostUsd).toFixed(2) | numberFormat }}
            </div>
          </div>
        </div>
        <svg class="chart-line periods" style="height: 14px; border-radius: 4px; width: 100%"></svg>
      </v-row>
    </v-col>
    <v-divider
      class="my-0 mx-5"
      vertical
      style="border-color: #E3E6EF;"
    />
    <v-col style="padding: 20px 30px">
      <v-row class="ma-0 mb-5">
        <span class="fz-16 fw-600 lh-22 text-black">Projects</span>
      </v-row>
      <v-sheet v-if="ordersStatuses" color="transparent" elevation="0" class="d-flex flex-wrap justify-space-between">
        <v-card
          v-for="item in projectsType" :key="item.color"
          class="rounded-xl d-flex align-center projects-small-card mb-5"
          :ripple="false"
          :class="{'small': $vuetify.breakpoint.lgAndDown, 'white--text' : item === selectedProjectType}"
          :style="{'background-color': item === selectedProjectType ? 'rgb(' + item.color + ')' : 'transparent !important',
          'box-shadow': item === selectedProjectType ? '0px 3px 6px rgba(' + item.color + ', .2) !important' : 'none'}"
          @click="selectProjectType(item)"
          elevation="0">
          <v-sheet
            class="rounded-xl d-flex align-center justify-center mr-2"
            :style="{'background-color': 'rgb(' + item.color + ')', 'box-shadow': '0px 3px 6px rgba(' + item.color + ', .2)'}"
            height="24"
            min-height="24"
            min-width="32"
            width="32">
            <span class="fz-12 fw-600 lh-22 white--text">{{ ordersStatuses[item.status] }}</span>
          </v-sheet>
          <span class="fz-12">{{ item.name }}</span>
        </v-card>
      </v-sheet>
    </v-col>
  </v-card>
</template>

<script>
import numberFormat from '@/helpers/numberFormat'
import * as d3 from 'd3'

export default {
  name: 'DashboardMinimize',
  props: ['projectsType', 'selectedProjectType', 'ordersStatuses', 'periods'],
  filters: {
    numberFormat
  },
  data () {
    return {
      tooltip: null
    }
  },
  computed: {
    totalValue() {
      let val = 0
      let valRub = 0
      let paidRub = 0
      let paidUsd = 0
      for (let i = 0; i < this.periods.length; i++) {
        val += +this.periods[i].totalCostUsd
        valRub += +this.periods[i].totalCostRub
        paidRub += +this.periods[i].paidRub
        paidUsd += +this.periods[i].paidUsd
      }
      return {
        usd: +val.toFixed(2),
        rub: Math.round(valRub),
        paidRub: Math.round(paidRub),
        paidUsd: Math.round(paidUsd)
      }
    }
  },
  methods: {
    selectProjectType(item) {
      if (item === this.selectedProjectType) this.$emit('update:selectedProjectType', null)
      else this.$emit('update:selectedProjectType', item)
    },
    createChart () {
      const vm = this
      const svg = d3.select('.chart-line')
      svg.selectAll('g').remove()
      const x = d3.scaleLinear()
        .domain([0, this.totalValue.usd]) //  <----- must change
        .range([0, svg._groups[0][0] ? svg._groups[0][0].parentNode.clientWidth : 0])
      const colors = this.periods.length > 1
        ? d3.scaleOrdinal()
          .domain(this.periods.map((d, i) => i))
          .range(d3.quantize(t => d3.interpolateSpectral(t * 0.99 + 0.1), this.periods.length).reverse())
        : d3.scaleOrdinal()
          .domain(this.periods.map((d, i) => i))
          .range(['#7552CC'])
      const xArr = []
      this.periods.map((el, i) => {
        el = i ? xArr[i - 1] * 1 + this.periods[i - 1].totalCostUsd * 1 : 0
        xArr.push(el)
      })
      const rect = svg
        .attr('height', '14')
        .append('g')
        .selectAll('rect')
        .data(this.periods)
        .join('rect')
        .attr('x', (d, i) => x(xArr[i]))
        .attr('fill', (d, i) => colors(i))
        .attr('width', (d, i) => x(d.totalCostUsd))
        .attr('height', '14px')
      rect.on('mouseover', function (event, d) {
        vm.tooltip = {
          x: d3.select(this).attr('x') * 1 + x(d.totalCostUsd) / 2 > 60 ? d3.select(this).attr('x') * 1 + x(d.totalCostUsd) / 2 : 60,
          name: d.period,
          totalCostUsd: d.totalCostUsd,
          totalCostRub: d.totalCostRub,
          paidRub: d.paidRub,
          paidUsd: d.paidUsd
        }
      })
      rect.on('mouseleave', function () {
        vm.tooltip = null
      })
    }
  },
  watch: {
    periods: {
      handler() {
        this.createChart()
      },
      deep: true
    }
  },
  mounted() {
    this.createChart()
  }
}
</script>

<style lang="scss">
.projects-small-card {
  transition: .26s;
  width: calc(33% - 13px);
  &:before {
    opacity: 0 !important;
  }
  &.small {
    width: calc(50% - 10px);
  }
}
.dashboard-minimize {
  .tooltip {
    position: absolute;
    top: -40px;
    background: #0E141E;
    border-radius: 8px;
    padding: 5px 10px;
    z-index: 500;
    transform: translateX(-50%);
  }
}
</style>
