<template>
  <v-sheet
    color="transparent"
    :class="chartName + '-parent'"
    height="180"
    elevation="0"
    class="chart-small"
  >
    <svg :class="chartName"></svg>
  </v-sheet>
</template>

<script>
import * as d3 from 'd3'
import { Locale } from 'v-calendar'
const locale = new Locale()

export default {
  name: 'ChartDesigner',
  props: ['chartName', 'chart', 'color'],
  computed: {
    chartNameSplit() {
      return this.chartName.replace(/\s+/g, '')
    }
  },
  methods: {
    formatDate(date) {
      return locale.format(date, 'DD.MM.YYYY')
    },
    createChart() {
      const self = this
      const margin = {
        top: 40,
        right: 12,
        bottom: 25,
        left: 50
      }
      const height = 180
      const width = Number.parseInt(
        d3.select('.' + this.chartName + '-parent').style('width')
      )
      const svg = d3
        .select('.' + this.chartName)
        .attr('id', this.chartNameSplit)
        .attr('height', height)
        .attr('width', width)
      svg.selectAll('*').remove()
      const x = d3
        .scaleLinear()
        .domain([0, this.chart.length - 1])
        .range([margin.left, width - margin.right])
      const y = d3
        .scaleLinear()
        .domain([0, d3.max([d3.max(this.chart.map((el) => +el.totalCostUsd)), 1])])
        .range([height - margin.bottom, margin.top])
      const yAxis = d3.axisLeft(y.nice())
      const xMonths = this.chart
        .map((el) => el.period)
        // .filter((el, i) => i !== 0 && i !== this.chart.length - 1)
      const xRange = d3
        .scaleBand()
        .domain(xMonths)
        .rangeRound([
          margin.left -
            (width - margin.left - margin.right) / (this.chart.length - 1) / 2,
          (width -
            margin.right) +
            (width - margin.left - margin.right) / (this.chart.length - 1) / 2
        ])
      const xAxis = d3
        .axisBottom(xRange)
        .tickSizeOuter(0)
        .tickValues(
          xMonths.filter(function (d, i) {
            return xMonths.length > 12
              ? !(i % Math.floor(xMonths.length / 12))
              : d
          })
        )
      svg
        .append('g')
        .attr('transform', `translate(0,${height - margin.bottom})`)
        .attr('class', 'chart-xAxis')
        .call(xAxis)
      svg
        .append('g')
        .attr('transform', 'translate(' + margin.left + ', 0)')
        .attr('class', 'chart-yAxis')
        .call(yAxis.ticks(5))
      svg
        .selectAll('.chart-yAxis line')
        .attr('x2', width - margin.right - margin.left)
        .attr('stroke', '#EEF2F7')
      const linearGradient = svg
        .append('defs')
        .append('linearGradient')
        .attr('id', 'linear-gradient-' + this.chartNameSplit)
        .attr('gradientTransform', 'rotate(90)')
      linearGradient
        .append('stop')
        .attr('offset', '0%')
        .attr('stop-color', 'rgba(' + this.color + ', 0.3)')
      linearGradient
        .append('stop')
        .attr('offset', '100%')
        .attr('stop-color', 'rgba(' + this.color + ', 0)')
      svg
        .append('path')
        .datum(this.chart)
        .attr('fill', 'url(#linear-gradient-' + this.chartNameSplit + ')')
        .attr(
          'd',
          d3
            .area()
            .x(function (d, i) {
              return x(i)
            })
            .y0(function (d) {
              return y(+d.totalCostUsd)
            })
            .y1(height - margin.bottom)
        )
      svg
        .append('path')
        .datum(this.chart)
        .attr('fill', 'none')
        .attr('stroke', 'rgb(' + this.color + ')')
        .attr('stroke-width', '2px')
        .attr(
          'd',
          d3
            .line()
            .x(function (d, i) {
              return x(i)
            })
            .y(function (d) {
              return y(d.totalCostUsd)
            })
        )
      const rect = svg
        .append('g')
        .attr('id', 'hover')
        .selectAll('.rect')
        .data(this.chart)
        .enter()
        .append('g')
      const hover = rect
        .append('rect')
        .attr('fill', 'transparent')
        .attr('x', function (d, i) {
          return x(i)
        })
        .attr('y', function () {
          return margin.top
        })
        .attr('height', height - margin.bottom - margin.top)
        .attr('width', (width - margin.right - margin.left) / (this.chart.length - 1))
        .style(
          'transform',
          'translateX(-' +
            (width - margin.right - margin.left) / (this.chart.length - 1) / 2 +
            'px)'
        )
      hover.on('mouseover', function (event, d) {
        const e = hover.nodes()
        const i = e.indexOf(this)
        self.tooltip(d, x(i), y(d.totalCostUsd), height - margin.bottom, width)
      })
      hover.on('mouseleave', function () {
        self.removeTooltip()
      })
      return svg.node()
    },
    tooltip(d, x, y, h, w) {
      const svg = d3.select('#' + this.chartNameSplit)
      const tooltipBG = svg.insert('g', '#hover').attr('class', 'tooltip')
      const tooltip = tooltipBG
        .append('rect')
        .attr('y', y - 40)
        .attr('height', 30)
        .attr('fill', '#000')
        .attr('rx', '8')
        .attr('ry', '8')
        .style('opacity', '0')
      const tooltipValue = tooltipBG
        .append('text')
        .attr('y', y - 20)
        .append('tspan')
        .text(d.period + ': $' + d.totalCostUsd)
        .style('font-size', '13px')
        .style('font-weight', '600')
        .style('fill', '#FFFFFF')
        .style('opacity', '0')
      const tooltipWidth = tooltipValue.node().getComputedTextLength() + 20
      tooltip
        .attr(
          'x',
          x - tooltipWidth / 2 < 0
            ? 0
            : x + tooltipWidth / 2 > w
              ? w - tooltipWidth
              : x - tooltipWidth / 2
        )
        .attr('width', tooltipWidth)
      tooltipValue.attr(
        'x',
        x - tooltipWidth / 2 < 0
          ? 10
          : x + tooltipWidth / 2 > w
            ? w + 10 - tooltipWidth
            : x + 10 - tooltipWidth / 2
      )
      tooltipBG
        .append('line')
        .attr('stroke', '#BFCEDB')
        .attr('x1', x)
        .attr('x2', x)
        .attr('y1', h)
        .attr('y2', y)
        .style('opacity', '.5')
      tooltipBG
        .append('circle')
        .attr('r', 5.5)
        .attr('fill', 'rgb(' + this.color + ')')
        .attr('stroke', 'white')
        .attr('stroke-width', '2px')
        .attr('cx', x)
        .attr('cy', y)
      // transitions
      tooltip.transition().duration(300).style('opacity', '1')
      tooltipValue.transition().duration(300).style('opacity', '1')
    },
    removeTooltip() {
      d3.select('#' + this.chartNameSplit)
        .selectAll('.tooltip')
        .remove()
    }
  },
  mounted() {
    const vm = this
    setTimeout(function () {
      vm.createChart()
    }, 0)
  }
}
</script>

<style lang="scss">
.chart-xAxis {
  .domain {
    display: none;
  }
  line {
    display: none;
  }
  g:last-child {
    text {
      transform: translateX(-14px);
    }
  }
  text {
    fill: #5A6176;
  }
}
.chart-yAxis {
  text {
    fill: #5A6176;
  }
  .domain {
    display: none;
  }
}

</style>
