<template>
  <v-col class="pa-5 height-100 d-flex flex-column hidden">
    <resize-observer @notify="tableHeight"/>
    <v-card v-if="deadline && deadlineShow" class="rounded-4 d-flex align-center mb-5" color="#FEF0F0" elevation="0" style="padding: 14px 24px">
      <v-icon class="mr-6 stroke-red" size="24">$deadline</v-icon>
      <span class="fz-13 font-weight-bold lh-20">Deadline</span>
      <v-icon class="my-auto mx-2" size="20">$arrowRight</v-icon>
      <span class="fz-13 font-weight-regular lh-20">{{deadline.order.name}}</span>
      <v-icon class="my-auto mx-2" size="20">$arrowRight</v-icon>
      <span style="color: #E30100" class="fz-13 font-weight-bold lh-20">{{ formatDate(deadline.expirationDate) }}</span>
    </v-card>
    <v-row class="ma-0 mb-12">
      <v-btn-toggle
          class="rounded-lg height-30 box-shadow interval black--text hidden"
          style="padding-left: 2px; padding-right: 2px; margin-right: 10px"
          background-color="#F2F4F5"
          v-model="interval"
          borderless
          dense>
          <v-btn
            class="rounded-lg my-auto fz-13 text-none"
            plain
            @click="getMonth"
            height="26">
            Month
          </v-btn>
          <v-btn
            class="rounded-lg my-auto fz-13 text-none"
            plain
            @click="getThreeMonth"
            height="26">
            3 months
          </v-btn>
          <v-btn
            class="rounded-lg my-auto fz-13 text-none"
            plain
            @click="getSixMonth"
            height="26">
            6 months
          </v-btn>
          <v-btn
            class="rounded-lg my-auto fz-13 text-none"
            plain
            @click="getYear"
            height="26">
            {{ getYearNow }} year
          </v-btn>
          <v-btn
            class="rounded-lg my-auto fz-13 text-none"
            plain
            @click="getAllTime"
            height="26">
            All time
          </v-btn>
        </v-btn-toggle>
      <v-btn
        color="#010820"
        class="rounded-lg mr-5 fz-13 fw-600 text-none"
        height="30"
        @click="showDatepicker"
        outlined>
        {{ queryOptions.dateStart && queryOptions.dateEnd ? 'Period: ' + formatDate(parseDate(queryOptions.dateStart)) + ' - ' + formatDate(parseDate(queryOptions.dateEnd)) : 'Specify period' }}
        <div @click.stop>
          <v-icon size="16" v-if="queryOptions.dateStart && queryOptions.dateEnd" class="ml-2" @click="getAllTime">mdi-close</v-icon>
        </div>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn class="btn-shrink text-none fz-14 fw-600 text-black rounded-lg" height="30" elevation="0" @click="minimize = !minimize">
        <template v-if="minimize">
          Expand
          <v-icon class="ml-2" size="14">$expand</v-icon>
        </template>
        <template v-else>
          Minimize
          <v-icon class="ml-2" size="14">$shrink</v-icon>
        </template>
      </v-btn>
    </v-row>
    <template v-if="periods">
      <dashboard-minimize
        v-if="minimize"
        :projectsType="projectsType"
        :selectedProjectType.sync="selectedProjectType"
        :ordersStatuses="ordersStatuses"
        :periods="periods"
      />
      <dashboard-expand
        v-else
        :projectsType="projectsType"
        :selectedProjectType.sync="selectedProjectType"
        :ordersStatuses="ordersStatuses"
        :queryOptions="queryOptions"
        :periods="periods"
      />
    </template>
    <chart-designer
      ref="chart"
      v-if="periods && periods.length > 1"
      chartName="dashboard"
      color="117,82,204"
      :chart="periods"
    />
    <dashboard-table
      @getOrder="$refs.editOrder.getOrder($event)"
      v-if="orders"
      :queryOptions.sync="queryOptions"
      :orders="orders"
      :tableOptions="tableOptions"
      :periods="periods"
    />
    <v-row class="ma-0 flex-grow-1"></v-row>
    <v-row class="mx-0 flex-grow-0">
      <v-btn
        height="40"
        class="my-auto pl-2 mr-1 rounded-lg text-none fz-14 fw-600"
        outlined
        :disabled="queryOptions.page === 1"
        @click="queryOptions.page--"
        color="primary">
        <v-icon>mdi-chevron-left</v-icon>
        Previous
      </v-btn>
      <v-pagination
        v-model="queryOptions.page"
        class="my-4 pagination text-black fw-600"
        :length="tableOptions.totalPages"
        :total-visible="7"
        previous-aria-label="Previous"
      >
      </v-pagination>
      <v-btn
        height="40"
        class="my-auto ml-1 pr-2 rounded-lg text-none fz-14 fw-600"
        outlined
        :disabled="tableOptions.totalPages === queryOptions.page"
        @click="queryOptions.page++"
        color="primary">
        Next
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-row>
    <vc-date-picker
      class="inline-block h-full"
      locale="en"
      :class="{'date-picker-orders': $vuetify.breakpoint.mdAndUp, 'date-picker-mobile': $vuetify.breakpoint.smAndDown}"
      :popover="{ visibility: 'click' }"
      :columns="$vuetify.breakpoint.mdAndUp ? 2 : 1"
      mode="range"
      is-range
      @popoverWillShow="overlay = true"
      @popoverDidHide="overlay = false"
      :masks="{input: 'DD.MM.YYYY', weekdays: 'WW'}"
      v-model="date">
      <template v-slot="">
        <button
          ref="datepickerShow"
          style="display: none"
        ></button>
      </template>
    </vc-date-picker>
    <!--    order view -->
    <EditOrder
      @getOrders="getDashboard"
      ref="editOrder"
    />
    <v-overlay
      :z-index="0"
      :value="overlay"
      color="rgba(9, 24, 73, 0.2)"
      opacity="1"
    />
    <div class="preloader" v-if="loading">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <v-snackbars
        :messages.sync="messages"
        color="red"
        timeout="2000"
        multi-line
        max-width="350"
        left
        rounded="lg"
        light
        elevation="4"
        content-class="snackbar"
      >
      <template v-slot:default="{ message }">
        <strong>{{ message.title }}</strong>
        <p>{{ message.text }}</p>
        <v-row v-if="message.title === 'Error'" class="ma-0 justify-end">
          <v-btn
            class="rounded-lg fz-12 fw-600 text-none border-none"
            outlined
            height="26"
            elevation="0"
            @click="doCopy('Error', message.text)"
          >Copy</v-btn>
        </v-row>
      </template>
    </v-snackbars>
  </v-col>
</template>

<script>
import { Locale } from 'v-calendar'
import DashboardExpand from '@/components/App/Dashboard/DashboardExpand'
import gql from 'graphql-tag'
import DashboardMinimize from '@/components/App/Dashboard/DashboardMinimize'
import DashboardTable from '@/components/App/Dashboard/DashboardTable'
import { ResizeObserver } from 'vue-resize'
import 'vue-resize/dist/vue-resize.css'
import EditOrder from '@/components/App/Orders/EditOrder'
import ChartDesigner from '@/components/App/charts/ChartDesigner'
import _ from 'lodash'
import VSnackbars from 'v-snackbars'
const locale = new Locale()

export default {
  name: 'DashboardBody',
  props: ['id', 'deadlineShow'],
  components: { ChartDesigner, EditOrder, DashboardTable, DashboardMinimize, DashboardExpand, ResizeObserver, 'v-snackbars': VSnackbars },
  //
  data () {
    return {
      messages: [],
      deadline: null,
      loading: false,
      interval: 4,
      minimize: false,
      queryOptions: {
        dateEnd: null,
        dateStart: null,
        orderBy: 'NUMBER_DESC',
        status: null,
        page: 1,
        pageSize: 13,
        searchClient: '',
        searchManager: '',
        searchName: ''
      },
      tableOptions: {
        totalItems: null,
        totalPages: null
      },
      projectsType: [
        {
          name: 'To do',
          color: '0, 135, 210',
          status: 'toDo',
          value: 'TODO'
        },
        {
          name: 'Backlog',
          color: '131, 131, 131',
          status: 'backlog',
          value: 'BACKLOG'
        },
        {
          name: 'Completed',
          color: '59, 213, 153',
          status: 'completed',
          value: 'COMPLETED'
        },
        {
          name: 'In progress',
          color: '253, 182, 0',
          status: 'inProgress',
          value: 'IN_PROGRESS'
        },
        {
          name: 'Review customer',
          color: '222, 0, 226',
          status: 'review',
          value: 'REVIEW'
        },
        {
          name: 'Need review',
          color: '126, 0, 224',
          status: 'needReview',
          value: 'NEED_REVIEW'
        }
      ],
      ordersStatuses: null,
      periods: null,
      selectedProjectType: null,
      orders: null,
      overlay: false
    }
  },
  computed: {
    getYearNow () {
      const date = new Date()
      return date.getFullYear()
    },
    date: {
      get () {
        const object = {
          end: this.queryOptions.dateEnd ? locale.parse(this.queryOptions.dateEnd, 'DD.MM.YYYY') : new Date(),
          start: this.queryOptions.dateStart ? locale.parse(this.queryOptions.dateStart, 'DD.MM.YYYY') : new Date()
        }
        return object
      },
      set (val) {
        this.interval = -1
        this.queryOptions.dateStart = val.start ? locale.format(val.start, 'YYYY-MM-DD') : ''
        this.queryOptions.dateEnd = val.end ? locale.format(val.end, 'YYYY-MM-DD') : ''
      }
    }
  },
  methods: {
    formatDate (date) {
      return locale.format(date, 'DD.MM.YYYY')
    },
    parseDate(date) {
      return locale.parse(date)
    },
    tableHeight () {
      this.queryOptions.pageSize = Math.floor((window.innerHeight - (this.minimize ? 580 : 700)) / 48) < 7 ? 7 : Math.floor((window.innerHeight - (this.minimize ? 580 : 700)) / 48)
      if (this.$refs.chart) this.chartResize()
    },
    chartResize: _.debounce(function () {
      if (this.periods && this.periods.length > 1) this.$refs.chart.createChart()
    }, 200),
    showDatepicker () {
      const vm = this
      setTimeout(function () {
        vm.$refs.datepickerShow.click()
      }, 0)
    },
    getMonth () {
      const end = new Date()
      const start = new Date()
      start.setMonth(start.getMonth() - 1)
      end.setDate(end.getDate() + 1)
      this.queryOptions.dateStart = locale.format(start, 'YYYY-MM-DD')
      this.queryOptions.dateEnd = locale.format(end, 'YYYY-MM-DD')
      const vm = this
      setTimeout(function () {
        vm.interval = 0
      }, 0)
    },
    getThreeMonth () {
      const end = new Date()
      const start = new Date()
      start.setMonth(start.getMonth() - 3)
      end.setDate(end.getDate() + 1)
      this.queryOptions.dateStart = locale.format(start, 'YYYY-MM-DD')
      this.queryOptions.dateEnd = locale.format(end, 'YYYY-MM-DD')
      const vm = this
      setTimeout(function () {
        vm.interval = 1
      }, 0)
    },
    getSixMonth () {
      const end = new Date()
      const start = new Date()
      start.setMonth(start.getMonth() - 6)
      end.setDate(end.getDate() + 1)
      this.queryOptions.dateStart = locale.format(start, 'YYYY-MM-DD')
      this.queryOptions.dateEnd = locale.format(end, 'YYYY-MM-DD')
      const vm = this
      setTimeout(function () {
        vm.interval = 2
      }, 0)
    },
    getYear () {
      this.queryOptions.dateStart = this.getYearNow + '-01-01'
      this.queryOptions.dateEnd = this.getYearNow + '-12-31'
      const vm = this
      setTimeout(function () {
        vm.interval = 3
      }, 0)
    },
    getAllTime () {
      this.queryOptions.dateStart = null
      this.queryOptions.dateEnd = null
      const vm = this
      setTimeout(function () {
        vm.interval = 4
      }, 0)
    },
    edit () {
      this.readOnly = false
    },
    async getDeadline() {
      if (this.$store.getters.getMe.designerUser) {
        await this.$apollo.query({
          query: gql`query deadline {
          dashboard {
            deadline {
              expirationDate
              order {
                name
              }
            }
          }
        }`,
          fetchPolicy: 'no-cache'
        }).then((data) => {
          this.deadline = data.data.dashboard.deadline
        }).catch((err) => {
          console.log(err)
        })
      }
    },
    async getDashboard () {
      this.loading = true
      await this.$apollo.query({
        query: gql`query getDashboard($input: DashboardInput!, $id: ID!) {
          dashboard {
            dashboardStatistics(input: $input, id: $id) {
              ordersStatuses {
                allOrders
                backlog
                completed
                needReview
                inProgress
                review
                toDo
              }
              periods {
                period
                totalCostUsd
                totalCostRub
                paidRub
                paidUsd
                toPayUsd
                toPayRub
              }
              totalItems
              totalPages
              data {
                id
                paidUsd
                paidRub
                toPayUsd
                toPayRub
                totalCostUsd
                totalCostRub
                items
                order {
                  number
                  name
                  client {
                    name
                  }
                  createdAt
                  dateEnd
                  dateEndPrediction
                  dateStart
                  expirationDate
                  updatedAt
                  currencySymbol
                  status {
                    label
                    value
                  }
                  manager {
                    businessInitials
                    businessName
                    avatarUrl
                  }
                }
              }
            }
          }
        }`,
        // Parameters
        variables: {
          id: this.id,
          input: {
            searchName: this.queryOptions.searchName,
            searchManager: this.queryOptions.searchManager,
            searchClient: this.queryOptions.searchClient,
            periodStart: this.queryOptions.dateStart ? this.queryOptions.dateStart : '2000-01-01',
            periodEnd: this.queryOptions.dateEnd ? this.queryOptions.dateEnd : '2900-01-01',
            page: this.queryOptions.page,
            pageSize: this.queryOptions.pageSize,
            orderBy: this.queryOptions.orderBy,
            status: this.selectedProjectType ? this.selectedProjectType.value : null
          }
        },
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.loading = false
        this.ordersStatuses = data.data.dashboard.dashboardStatistics.ordersStatuses
        this.periods = data.data.dashboard.dashboardStatistics.periods
        this.tableOptions.totalItems = data.data.dashboard.dashboardStatistics.totalItems
        this.tableOptions.totalPages = data.data.dashboard.dashboardStatistics.totalPages
        this.orders = data.data.dashboard.dashboardStatistics.data
      }).catch((err) => {
        this.loading = false
        console.log(err)
      })
    },
    addSnack (item, result, text) {
      this.messages.push({ title: result, text: text })
    }
  },
  created() {
    this.tableHeight()
  },
  mounted() {
    this.getDeadline()
    // this.getDashboard()
  },
  watch: {
    queryOptions: {
      handler() {
        if (this.id !== null) {
          this.getDashboard()
        }
      },
      deep: true
    },
    selectedProjectType: {
      handler() {
        this.getDashboard()
      },
      deep: true
    },
    periods: {
      handler() {
        this.chartResize()
      },
      deep: true
    },
    minimize() {
      this.tableHeight()
    },
    id() {
      this.getDashboard()
    }
  }
}
</script>
