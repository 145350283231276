<template>
  <v-row class="ma-0">
    <v-col class="pa-0">
      <v-data-table
        @click:row="getOrder"
        class="mt-5 orders-table table-vertical-line"
        :headers="headers"
        :items="orders"
        :header-props="{ sortIcon: 'mdi-menu-down' }"
        :server-items-length="tableOptions.totalItems"
        hide-default-footer
        :options.sync="options"
      >
        <template v-slot:header.name="">
          <v-text-field
            @click.stop
            prepend-inner-icon="$search"
            placeholder="Project"
            v-model="queryOptions.searchName"
            outlined
            clearable
            class="table-search d-inline-block ma-0 input-clear-small"
            dense
          ></v-text-field>
        </template>
        <template v-slot:header.client="">
          <v-text-field
            @click.stop
            prepend-inner-icon="$search"
            placeholder="Client"
            v-model="queryOptions.searchClient"
            outlined
            clearable
            class="table-search width-140 d-inline-block ma-0 input-clear-small"
            dense
          ></v-text-field>
        </template>
        <template v-slot:header.manager="">
          <v-text-field
            @click.stop
            prepend-inner-icon="$search"
            placeholder="Manager"
            v-model="queryOptions.searchManager"
            outlined
            clearable
            class="table-search width-140 d-inline-block ma-0 input-clear-small"
            dense
          ></v-text-field>
        </template>
        <template v-slot:item.number="{ item }">
          <v-row class="ma-0">
            <span>{{ item.order.number }}</span>
          </v-row>
        </template>
        <template v-slot:item.status="{ item }">
          <v-row class="ma-0 align-center flex-nowrap">
            <span style="display: inline-block; width: 8px; height: 8px; margin-right: 6px;" class="rounded-circle" :style="color(item.order.status.value)"></span>
            <span style="max-width: 80px">{{ item.order.status.label }}</span>
          </v-row>
        </template>
        <template v-slot:item.name="{ item }">
          <v-row class="ma-0">
            <span>{{ item.order.name }}</span>
          </v-row>
        </template>
        <template v-slot:item.dateStart="{ item }">
          <v-row class="ma-0">
            <span>{{ formatDate(item.order.createdAt) }}</span>
          </v-row>
        </template>
        <template v-slot:item.updatedAt="{ item }">
          <v-row class="ma-0">
            <span>{{ formatDate(item.order.updatedAt) }}</span>
          </v-row>
        </template>
        <template v-slot:item.dateEnd="{ item }">
          <v-col class="pa-0">
            <v-row class="ma-0">
              <span class="one-line"
                :class="{'color-primary': item.order.dateEnd !== item.order.dateEndPrediction, 'fw-600': item.order.dateEnd !== item.order.dateEndPrediction}">
                {{ item.order.dateEnd ? formatDate(item.order.dateEnd) : '' }}
              </span>
            </v-row>
            <v-row v-if="item.order.dateEnd !== item.order.dateEndPrediction" class="ma-0">
              <span class="one-line fz-10 mt-n1">
                {{ formatDate(item.order.dateEndPrediction) }}
              </span>
            </v-row>
          </v-col>
        </template>
        <template v-slot:item.client="{ item }">
          <v-row class="ma-0">
            <span>{{ item.order.client.name }}</span>
          </v-row>
        </template>
        <template v-slot:item.paid="{ item }">
          <v-row class="ma-0">
            <span style="white-space: nowrap">
            </span>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                  style="white-space: nowrap"
                >
                  {{ Math.round(item.paidRub) | numberFormat }}₽
                </div>
              </template>
              <template>
                <div style="white-space: nowrap">
                  ${{ (+item.paidUsd).toFixed(2) | numberFormat }}
                </div>
              </template>
            </v-tooltip>
          </v-row>
        </template>
        <template v-slot:item.totalCost="{ item }">
          <v-row class="ma-0 flex-column">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                  style="white-space: nowrap"
                >
                  {{ Math.round(item.totalCostRub) | numberFormat }}₽
                </div>
              </template>
              <template>
                <div style="white-space: nowrap">
                  ${{ (+item.totalCostUsd).toFixed(2) | numberFormat }}
                </div>
              </template>
            </v-tooltip>
          </v-row>
        </template>
        <template v-slot:item.toPay="{ item }">
          <v-row class="ma-0 flex-column">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                  style="white-space: nowrap"
                >
                  {{ Math.round(item.toPayRub) | numberFormat }}₽
                </div>
              </template>
              <template>
                <div style="white-space: nowrap">
                  ${{ (+item.toPayUsd).toFixed(2) | numberFormat }}
                </div>
              </template>
            </v-tooltip>
          </v-row>
        </template>
        <template v-slot:item.manager="{ item }">
          <v-row class="ma-0">
            <span>{{ item.order.manager.businessName }}</span>
          </v-row>
        </template>
        <template v-slot:body.append>
          <tr class="table-footer">
            <td colspan="8">
              <span class="text-black fw-600 fz-13">Total</span>
              <span>&nbsp;*All pages</span>
            </td>
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                    class="text-black fw-600 fz-13"
                  >
                  {{ totalValue.paidRub | numberFormat }}₽
                </span>
                </template>
                <template>
                  ${{ totalValue.paidUsd | numberFormat }}
                </template>
              </v-tooltip>
            </td>
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                    class="text-black fw-600 fz-13"
                  >
                  {{ totalValue.toPayRub | numberFormat }}₽
                </span>
                </template>
                <template>
                  ${{  totalValue.toPayUsd | numberFormat }}
                </template>
              </v-tooltip>
            </td>
            <td colspan="3">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                    class="text-black fw-600 fz-13"
                  >
                  {{ totalValue.totalCostRub | numberFormat}}₽
                </span>
                </template>
              <template>
                ${{ totalValue.totalCostUsd | numberFormat}}
              </template>
            </v-tooltip>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { Locale } from 'v-calendar'
import { statusColor } from '@/helpers/staticColors'
import numberFormat from '@/helpers/numberFormat'
const locale = new Locale()

export default {
  name: 'DashboardTable',
  props: ['queryOptions', 'orders', 'tableOptions', 'periods'],
  filters: {
    numberFormat
  },
  data() {
    return {
      headers: [
        { text: 'Number', align: 'start', value: 'number', filterable: false, cellClass: 'pointer' },
        { text: 'Status', value: 'status', filterable: false, cellClass: 'pointer' },
        { text: 'Project', value: 'name', filterable: false, sortable: false, cellClass: 'pointer' },
        { text: 'Start date', value: 'dateStart', filterable: false, cellClass: 'pointer' },
        { text: 'Update date', value: 'updatedAt', filterable: false, cellClass: 'pointer' },
        { text: 'Expiration date', value: 'dateEnd', filterable: false, cellClass: 'pointer' },
        { text: 'Client', value: 'client', sortable: false, cellClass: 'pointer' },
        { text: 'Manager', value: 'manager', filterable: false, sortable: false, cellClass: 'pointer' },
        { text: 'Paid', value: 'paid', filterable: false, cellClass: 'pointer' },
        { text: 'To Pay', value: 'toPay', filterable: false, cellClass: 'pointer' },
        { text: 'Total cost', value: 'totalCost', filterable: false, cellClass: 'pointer' }
      ],
      options: {
        itemsPerPage: 999
      }
    }
  },
  computed: {
    totalValue() {
      let paidRub = 0
      let toPayRub = 0
      let totalCostRub = 0

      let paidUsd = 0
      let toPayUsd = 0
      let totalCostUsd = 0
      for (let i = 0; i < this.periods.length; i++) {
        paidRub += +this.periods[i].paidRub
        toPayRub += +this.periods[i].toPayRub
        totalCostRub += +this.periods[i].totalCostRub

        paidUsd += +this.periods[i].paidUsd
        toPayUsd += +this.periods[i].toPayUsd
        totalCostUsd += +this.periods[i].totalCostUsd
      }
      return {
        paidRub: Math.round(paidRub),
        toPayRub: Math.round(toPayRub),
        totalCostRub: Math.round(totalCostRub),
        paidUsd: Math.round(paidUsd),
        toPayUsd: Math.round(toPayUsd),
        totalCostUsd: Math.round(totalCostUsd)
      }
    }
  },
  methods: {
    formatDate (date) {
      return locale.format(date, 'DD.MM.YYYY')
    },
    color (value) {
      return statusColor(value)
    },
    sortTable () {
      let title = ''
      if (this.options.sortBy.length) {
        switch (this.options.sortBy[0]) {
          case 'number': title = 'NUMBER'
            break
          case 'status': title = 'STATUS'
            break
          case 'name': title = 'PROJECT'
            break
          case 'dateStart': title = 'DATE_START'
            break
          case 'updatedAt': title = 'UPDATED_AT'
            break
          case 'dateEnd': title = 'EXPIRATION_DATE'
            break
          case 'client': title = 'CLIENT_COST'
            break
          case 'totalCost': title = 'FINAL_COST'
            break
          case 'manager': title = 'MANAGER_COST'
            break
        }
        if (this.options.sortDesc[0] === true) title += '_DESC'
        else title += '_ASC'
        this.queryOptions.orderBy = title
      }
    },
    getOrder (item) {
      this.$emit('getOrder', item.id)
    }
  },
  watch: {
    options: {
      handler () {
        this.sortTable()
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
