<template>
  <v-row class="ma-0">
    <v-col class="pa-0">
      <v-row class="ma-0">
        <span class="fz-16 fw-600 lh-22 text-black">Income</span>
      </v-row>
      <v-divider style="border-color: #E3E6EF; margin: 30px 0;"/>
      <v-row class="ma-0 relative">
        <v-sheet color="transparent" class="relative mr-7" elevation="0" width="200" height="200">
          <svg class="chart-donut periods"></svg>
          <div v-if="periods.length" class="dashboard-chart-tooltip font-weight-medium">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                  class="text-no-wrap d-flex flex-column"
                >
                  <span>
                    {{ (tooltip.paidRub ? Math.round(+tooltip.paidRub) : +totalValue.paidRub) | numberFormat }}₽
                  </span>
                  <span class="text-gray">
                    {{ (tooltip.totalCostRub ? Math.round(+tooltip.totalCostRub) : +totalValue.rub) | numberFormat }}₽
                  </span>
                </div>
              </template>
              <template>
                <span>
                  ${{ (tooltip.paidUsd ? Math.round(+tooltip.paidUsd) : +totalValue.paidUsd) | numberFormat }}
                </span>
                <span class="text-gray">
                  ${{ (tooltip.totalCostUsd ? Math.round(+tooltip.totalCostUsd) : +totalValue.usd) | numberFormat }}
                </span>
              </template>
            </v-tooltip>
            <p class="ma-0 mt-1 fz-14 text-black text-center" v-if="tooltip.totalCostUsd">{{ tooltip.period }}</p>
          </div>
        </v-sheet>
        <v-sheet class="ma-0" elevation="0" color="transparent">
          <!--Common for all periods-->
          <span
            style="margin-bottom: 15px"
            class="fz-13 lh-20"
            v-if="periods.length > 6"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                >
                  Average per month: {{ (totalValue.rub / periods.length) | numberFormat }}₽
                </span>
              </template>
              <template>
                Average per month: ${{ (totalValue.usd / periods.length) | numberFormat }}
              </template>
            </v-tooltip>
          </span>
          <v-row class="ma-0">
            <!--First column-->
            <v-col
              class="px-0 py-3"
              :class="{'mr-10': periods.length > 6}"
            >
              <template v-for="(item, index) in periods">
                <v-row
                  v-if="index < 6"
                  class="mx-0 mt-0 align-center flex-nowrap"
                  :key="index"
                  :style="queryOptions.dateStart ? periods.length > 6 ? 'margin-bottom: 5px' : 'margin-bottom: 10px' : 'margin-bottom: 0'"
                >
                  <v-sheet
                    v-if="colors"
                    width="12"
                    height="12"
                    class="rounded"
                    elevation="0"
                    :color="colors(index)"
                    style="margin-right: 10px"
                  />
                  <span class="fz-13 lh-20 text-black text-no-wrap">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ item.period + ': ' }}{{ +Math.round(item.totalCostRub) | numberFormat }}₽
                        </span>
                      </template>
                      <template>
                        {{ item.period + ': $' }}{{ +Math.round(item.totalCostUsd) | numberFormat }}
                      </template>
                    </v-tooltip>
                  </span>
                </v-row>
                <v-row
                  v-if="!queryOptions.dateStart && index < 6"
                  :key="index + 'average'"
                  class="mt-0 mx-0"
                  style="margin-bottom: 10px"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <p
                          v-bind="attrs"
                          v-on="on"
                          class="my-0 fz-13 lh-20 text-dgray"
                          style="margin-left: 22px"
                        >
                          Average per month: {{ item.totalCostRub / 12 | numberFormat }}₽
                        </p>
                    </template>
                    <template>
                        Average per month: ${{ (item.totalCostUsd / 12).toFixed(2) | numberFormat }}
                    </template>
                  </v-tooltip>
                </v-row>
              </template>
            </v-col>
            <!--Second column-->
            <v-col v-if="periods.length > 6" class="px-0 py-3">
              <template v-for="(item, index) in periods">
                <v-row
                  v-if="index > 5"
                  class="mx-0 mt-0 align-center flex-nowrap"
                  :key="index"
                  :style="queryOptions.dateStart ? periods.length > 6 ? 'margin-bottom: 5px' : 'margin-bottom: 10px' : 'margin-bottom: 0'"
                >
                  <v-sheet
                    v-if="colors"
                    width="12"
                    height="12"
                    class="rounded"
                    elevation="0"
                    :color="colors(index)"
                    style="margin-right: 10px"
                  />
                  <span class="fz-13 lh-20 text-black text-no-wrap">
                    <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ item.period + ': ' }}{{ +Math.round(item.totalCostRub) | numberFormat }}₽
                        </span>
                    </template>
                    <template>
                      {{ item.period + ': $' }}{{ +Math.round(item.totalCostUsd) | numberFormat }}
                    </template>
                  </v-tooltip>
                  </span>
                </v-row>
                <v-row
                  v-if="!queryOptions.dateStart && index > 5"
                  :key="index + 'average'"
                  class="mt-0 mx-0"
                  style="margin-bottom: 10px"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <p
                        v-bind="attrs"
                        v-on="on"
                        class="my-0 fz-13 lh-20 text-dgray"
                        style="margin-left: 22px"
                      >
                        Average per month: {{ Math.round(item.totalCostRub / 12) | numberFormat }}₽
                      </p>
                    </template>
                    <template>
                        Average per month: ${{ Math.round(item.totalCostUsd / 12) | numberFormat }}
                    </template>
                  </v-tooltip>
                </v-row>
              </template>
            </v-col>
          </v-row>
        </v-sheet>
      </v-row>
    </v-col>
    <v-divider vertical class="mx-12 my-0" style="border-color: #E3E6EF"></v-divider>
    <v-col class="pa-0">
      <v-row class="ma-0">
        <span class="fz-16 fw-600 lh-22 text-black">Projects</span>
      </v-row>
      <v-divider style="border-color: #E3E6EF; margin: 30px 0;"></v-divider>
      <v-sheet v-if="ordersStatuses" color="transparent" elevation="0" class="d-flex flex-wrap justify-space-between">
        <v-card
          v-for="item in projectsType" :key="item.color"
          class="rounded-lg d-flex align-center projects-card mb-5"
          :ripple="false"
          :class="{'small': $vuetify.breakpoint.lgAndDown, 'active' : item === selectedProjectType}"
          @click="selectProjectType(item)"
          elevation="0">
          <v-sheet
            class="rounded-circle d-flex align-center justify-center mr-2"
            :style="{'background-color': 'rgb(' + item.color + ')', 'box-shadow': '0px 3px 6px rgba(' + item.color + ', .2)'}"
            height="42"
            min-height="42"
            min-width="42"
            width="42">
            <span class="fz-16 fw-600 lh-22 white--text">{{ ordersStatuses[item.status] }}</span>
          </v-sheet>
          <span>{{ item.name }}</span>
        </v-card>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import numberFormat from '@/helpers/numberFormat'
import * as d3 from 'd3'

export default {
  name: 'DashboardExpand',
  props: ['projectsType', 'selectedProjectType', 'ordersStatuses', 'periods', 'queryOptions'],
  filters: {
    numberFormat
  },
  data() {
    return {
      tooltip: {
        totalCostUsd: null,
        totalCostRub: null,
        period: null
      },
      colors: null
    }
  },
  computed: {
    totalValue() {
      let val = 0
      let valRub = 0
      let paidRub = 0
      let paidUsd = 0
      for (let i = 0; i < this.periods.length; i++) {
        val += +this.periods[i].totalCostUsd
        valRub += +this.periods[i].totalCostRub
        paidRub += +this.periods[i].paidRub
        paidUsd += +this.periods[i].paidUsd
      }
      return {
        usd: +val.toFixed(2),
        rub: Math.round(valRub),
        paidRub: Math.round(paidRub),
        paidUsd: Math.round(paidUsd)
      }
    }
  },
  methods: {
    selectProjectType(item) {
      if (item === this.selectedProjectType) this.$emit('update:selectedProjectType', null)
      else this.$emit('update:selectedProjectType', item)
    },
    createChart () {
      const vm = this
      const pie = d3.pie()
        .padAngle(0.0)
        .sort(null) // (a, b) => b.value - a.value
        .value(d => d.totalCostUsd)
      const arc = d3.arc().innerRadius(90 * 0.90).outerRadius(90 - 1)
      const arcHover = d3.arc().innerRadius(90 * 0.85).outerRadius(90 + 3)
      const colors = this.periods.length > 1
        ? d3.scaleOrdinal()
          .domain(this.periods.map((d, i) => i))
          .range(d3.quantize(t => d3.interpolateSpectral(t * 0.99 + 0.1), this.periods.length).reverse())
        : d3.scaleOrdinal()
          .domain(this.periods.map((d, i) => i))
          .range(['#7552CC'])
      this.colors = colors
      const arcs = pie(this.periods)
      const svg = d3.select('.periods')
      svg.selectAll('g').remove()
      const g = svg.append('g')
        .attr('transform', 'translate(100 100)')
      const path = g.selectAll('path')
        .data(arcs)
        .join('path')
        .attr('fill', (d, i) => colors(i))
        .attr('d', arc)
      path.on('mouseover', function (event, d) {
        g.selectAll('path')
          .attr('style', 'opacity: .6')
        d3.select(this)
          .attr('style', 'opacity: 1')
          .attr('d', arcHover)
        vm.tooltip = {
          totalCostUsd: d.data.totalCostUsd,
          totalCostRub: d.data.totalCostRub,
          paidRub: d.data.paidRub,
          period: d.data.period
        }
      })
      path.on('mouseleave', function (event, d) {
        g.selectAll('path')
          .attr('style', 'opacity: 1')
        d3.select(this)
          .attr('d', arc)
        vm.tooltip = {}
      })
    }
  },
  watch: {
    periods: {
      handler() {
        this.createChart()
      },
      deep: true
    }
  },
  mounted() {
    this.createChart()
  }
}
</script>

<style lang="scss">
.projects-card {
  transition: .26s;
  width: calc(33% - 13px);
  padding: 10px;
  border: 1px solid #EEF2F7 !important;
  &:before {
    opacity: 0 !important;
  }
  &.small {
    width: calc(50% - 10px);
  }
  &.active {
    background-color: #EEF2F7;
  }
}
.dashboard-chart-tooltip {
  position: absolute;
  top: 50%;
  left: 100px;
  transform: translate(-50%, -50%);
  font-size: 20px;
  font-weight: 500;
}
</style>
